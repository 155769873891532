/* @flow */

import React from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import AmountFormatterComponent from 'components/formatters/amount';
import ButtonComponent from 'components/buttons/button';
import DateFormatterComponent from 'components/formatters/date';
import IntlMessageComponent from 'components/formatters/intlMessage';
import PaymentByTransferComponent from 'components/payments/paymentByTransfer';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';
import TimeFormatterComponent from 'components/formatters/time';

import BuyerTransferInvoicesTableViewComponent from '../invoiceTable';

import BuyerInvoice from 'models/buyers/invoices/invoice';
import WireTransferPayment from 'models/payments/wireTransferPayment';

type BuyerTransferInvoicesPaymentSummaryComponentProps = {
  invoices: BuyerInvoice[];
  wireTransferPayment: WireTransferPayment;
  confirmPayment: () => void;
  payLater: () => void;
}

export default function BuyerTransferInvoicesPaymentSummaryComponent(props: BuyerTransferInvoicesPaymentSummaryComponentProps) {
  return (
    <React.Fragment>
      <Box width="66vw">
        <PaymentByTransferComponent wireTransferPayment={props.wireTransferPayment} />
      </Box>

      <Box mt={3}>
        <Divider />
      </Box>

      <Box mt={3}>
        <PageSubTitleViewComponent id="buyer.transfer.pay.title" />
      </Box>

      <div className='hiddenMobile'>
        <Box mt={3}>
          <Box width="50%" display="flex">
            <Box p={1} flexGrow={1}>
              {props.invoices.some(i => i.isConfirming) && <IntlMessageComponent id="buyer.transfer.pay.confirmingtransfer" />}
              {props.invoices.some(i => !i.isConfirming) && <IntlMessageComponent id="buyer.transfer.pay.purchacePrice" />}
            </Box>
            <Box p={1}>
              <Box component="span" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold" textAlign="right">
                <AmountFormatterComponent amount={props.wireTransferPayment.amount} />
              </Box>
            </Box>
          </Box>

          <Box width="50%" display="flex">
            <Box p={1} flexGrow={1}>
              <IntlMessageComponent id="buyer.transfer.pay.dueDate" />
            </Box>
            <Box p={1}>
              <Box color="green.main" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold" textAlign="right">
                <DateFormatterComponent date={props.wireTransferPayment.dueDate} />
              </Box>
              <Box color="green.main" textAlign="right">
                <TimeFormatterComponent date={props.wireTransferPayment.dueDate} />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
      <div className='hiddenDesktop'>
        <Box mt={3}>
          <Box width="66vw" display="flex">
            <Box p={1} flexGrow={1}>
              <IntlMessageComponent id="buyer.transfer.pay.purchacePrice" />
            </Box>
            <Box p={1}>
              <Box component="span" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold" textAlign="right">
                <AmountFormatterComponent amount={props.wireTransferPayment.amount} />
              </Box>
            </Box>
          </Box>

          <Box width="66vw" display="flex">
            <Box p={1} flexGrow={1}>
              <IntlMessageComponent id="buyer.transfer.pay.dueDate" />
            </Box>
            <Box p={1}>
              <Box color="green.main" fontSize="min(4vw, 30px);" fontWeight="fontWeightBold" textAlign="right">
                <DateFormatterComponent date={props.wireTransferPayment.dueDate} />
              </Box>
              <Box color="green.main" textAlign="right">
                <TimeFormatterComponent date={props.wireTransferPayment.dueDate} />
              </Box>
            </Box>
          </Box>
        </Box>
      </div>

      <div className="hiddenMobile">
        <Box mt={3}>
          <ButtonComponent color="primary" variant="contained" onClick={props.confirmPayment}>
            <IntlMessageComponent id="buyer.transfer.pay.btn.confirm" />
          </ButtonComponent>

          <Box component="span" ml={1}>
            <ButtonComponent color="secondary" variant="contained" onClick={props.payLater}>
              <IntlMessageComponent id="buyer.transfer.pay.btn.payLater" />
            </ButtonComponent>
          </Box>
        </Box>
      </div>
      <div className="hiddenDesktop">
        <Box mt={3}>
          <ButtonComponent color="primary" variant="contained" onClick={props.confirmPayment}>
            <IntlMessageComponent id="buyer.transfer.pay.btn.confirm" />
          </ButtonComponent>

          <Box mt={1}>
            <ButtonComponent color="secondary" variant="contained" onClick={props.payLater}>
              <IntlMessageComponent id="buyer.transfer.pay.btn.payLater" />
            </ButtonComponent>
          </Box>
        </Box>
      </div>
      <Box mt={3}>
        <Divider />
      </Box>

      <Box mt={3}>
        <PageSubTitleViewComponent id="buyer.transfer.pay.invoiceList.title" />
        <BuyerTransferInvoicesTableViewComponent invoices={props.invoices} />
      </Box>
    </React.Fragment>
  );
}
